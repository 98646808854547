import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { api } from '@mf/common/config/axios/serviceApi';
import { TAxiosDefaultConfig } from '@mf/common/repositories/aecomStrapiCms/defaults/types';
import { TTemplateBody, TTemplateResponseData } from './types';

const bffTemplate = ({ baseUrl, token }: TAxiosDefaultConfig) => {
  const apiBff: AxiosInstance = api({ baseUrl, token });

  const getTemplates = async ({
    finalProductId,
    body,
  }: {
    finalProductId: string;
    body: TTemplateBody;
  }): Promise<AxiosResponse<TTemplateResponseData>> => {
    const url = `/v1/products/${finalProductId}/templates`;
    try {
      const response: AxiosResponse<TTemplateResponseData> = await apiBff.post(
        url,
        body,
      );
      return response;
    } catch (error) {
      const error_ = error as AxiosError;
      return error_.response as AxiosResponse<TTemplateResponseData>;
    }
  };

  const useTemplateDownload = () => {
    return useMutation(getTemplates);
  };

  return {
    getTemplates,
    useTemplateDownload,
  };
};

export default bffTemplate;
