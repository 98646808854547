import bffTemplate from '@mf/common/repositories/bff/templates/index';
import { TPartCombination } from '@mf/common/store/product-configuration';
import { TTemplate } from '@mf/common/repositories/bff/templates/types';
import { useCallback } from 'react';

interface FetchTemplatesProps {
  parts: TPartCombination[];
  finalProductId?: string;
}

export type FileWithOrientation = {
  orientation: string;
  type: string;
  url: string;
  parts?: { id: string; url: string }[];
};

export const useDrawerTemplatesHooks = () => {
  const { useTemplateDownload } = bffTemplate({
    baseUrl: process.env.BFF_API_BASE_URL,
  });

  const {
    data: templates,
    mutate: mutateTemplates,
    isLoading,
    isSuccess,
  } = useTemplateDownload();

  const fetchTemplates = ({ parts, finalProductId }: FetchTemplatesProps) => {
    if (!finalProductId) {
      return;
    }

    const payload = {
      finalProductId,
      body: {
        product_parts: parts,
      },
    };

    mutateTemplates(payload);
  };

  const getOrientation = (orientation: string) => {
    const orientations = {
      unique: 'Única',
      horizontal: 'Horizontal',
      vertical: 'Vertical',
    };

    return orientations[orientation as keyof typeof orientations];
  };

  const getProps = (type: string) => {
    const types = {
      illustrator: {
        name: 'Illustrator',
        tag: '.ai',
        img: 'https://d1br4h274rc9sc.cloudfront.net/content/ai_d2e3803c4a.svg',
      },
      photoshop: {
        name: 'Photoshop',
        tag: '.psd',
        img: 'https://d1br4h274rc9sc.cloudfront.net/content/psd_09083d03f4.svg',
      },
      indesign: {
        name: 'Indesign',
        tag: '.indd',
        img: 'https://d1br4h274rc9sc.cloudfront.net/content/indd_e2eeecac60.svg',
      },
      pdf: {
        name: 'Acrobat Reader',
        tag: '.pdf',
        img: 'https://d1br4h274rc9sc.cloudfront.net/content/pdf_1403bd3b33.svg',
      },
      coreldraw: {
        name: 'Corel Draw',
        tag: '.cdr',
        img: 'https://d1br4h274rc9sc.cloudfront.net/content/cdr_76c0cfa08b.svg',
      },
      canva: {
        name: 'Canva',
        tag: '',
        img: 'https://d1br4h274rc9sc.cloudfront.net/content/Canva_a2873a4aae_803b7c4045.svg',
      },
    };

    return types[type as keyof typeof types];
  };

  const sortFilesByType = useCallback((items: TTemplate[]) => {
    const allFiles: FileWithOrientation[] = items.flatMap((item) =>
      item.files.map((file) => ({
        ...file,
        orientation: item.orientation,
      })),
    );

    return allFiles.sort((a, b) => a.type.localeCompare(b.type));
  }, []);

  return {
    templates,
    isLoading,
    fetchTemplates,
    getOrientation,
    getProps,
    sortFilesByType,
    isSuccess,
  };
};
