'use client';

import {
  ButtonIconHighlightSize,
  CurrencyPosition,
  CurrencySize,
  FsButtonIconHighlight,
  FsCardProduct,
  FsCurrency,
  FsDescription,
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import React, { useState } from 'react';
import Link from 'next/link';
import { useLayout } from '@mf/common/components/Media/Layout';
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import Image from 'next/image';
import * as S from './CarouselRelatedProducts.styles';

type TCardsRelatedProducts = {
  name: string;
  small_image: string;
  small_image_hover?: string;
  minimal_price: number;
  slug: string;
  description?: string;
  minimal_quantity: number;
};
interface CarouselProps {
  isDrawerCarousel?: boolean;
  heading?: string;
  cards: TCardsRelatedProducts[];
}

const Card = ({
  small_image_hover,
  small_image,
  minimal_price,
  minimal_quantity,
  name,
  description = 'A partir de',
}: TCardsRelatedProducts) => {
  return (
    <FsCardProduct
      image={<Image src={small_image} alt={name} width={250} height={330} />}
      hoverImage={
        (
          <Image
            src={small_image_hover || small_image}
            alt="Placeholder"
            width={250}
            height={330}
          />
        ) as React.ReactNode & string
      }
      showTag={false}
    >
      <>
        <FsHeading size={HeadingSize.XS} tag={HeadingTag.H3}>
          {name || 'Produto'}
        </FsHeading>
        <FsDescription>{description}</FsDescription>
        <FsCurrency
          price={`R$ ${formatCurrency(minimal_price || 0, { precision: 2 }) || '00,00'} ${'/'}`}
          size={CurrencySize.SM}
          description={
            minimal_quantity !== undefined && minimal_quantity !== null
              ? `${minimal_quantity.toString()} unid.`
              : ''
          } // precisa checar se é null E undefined porque "0" é incluso em qualquer outra coerção 'falsy' JS
          descriptionPosition={CurrencyPosition.Right}
        />
      </>
    </FsCardProduct>
  );
};

//TODO making this on Carousel Component => Export Carousel, Listing Carousel etc..
//TODO Remove dependecy from carousel and card product
export const CarouselRelatedProducts = ({
  isDrawerCarousel = false,
  heading = 'Heading',
  cards,
}: CarouselProps) => {
  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex >= totalCards - (isDrawerCarousel ? 2 : 4) ? 0 : prevIndex + 1,
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex <= 0 ? totalCards - (isDrawerCarousel ? 2 : 4) : prevIndex - 1,
    );
  };

  const { isMobile } = useLayout();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const totalCards = cards.length;

  return (
    <S.Carousel className="carousel-component">
      <S.HeadingControlsWrapper>
        <FsHeading
          tag={HeadingTag.H2}
          size={isMobile ? HeadingSize.XS : HeadingSize.SM}
        >
          {heading}
        </FsHeading>
        <S.ButtonsWrapper>
          <FsButtonIconHighlight
            size={ButtonIconHighlightSize.LG}
            icon="NavigationLeftLine"
            onClick={handlePrev}
            disabled={activeIndex === 0}
          />
          <FsButtonIconHighlight
            size={ButtonIconHighlightSize.LG}
            icon="NavigationRightLine"
            onClick={handleNext}
            disabled={activeIndex === totalCards - (isDrawerCarousel ? 2 : 4)}
          />
        </S.ButtonsWrapper>
      </S.HeadingControlsWrapper>
      <S.CardsCarouselContainer>
        {isMobile ? (
          <S.CardsCarouselWrapper>
            {(cards || []).map((product) => (
              <S.CardProductWrapper key={product.slug}>
                <Link href={`/${product.slug}/`}>
                  <Card {...product} description="A partir de" />
                </Link>
              </S.CardProductWrapper>
            ))}
          </S.CardsCarouselWrapper>
        ) : (
          <S.CardsCarouselWrapper
            style={{
              transform: `translateX(-${activeIndex * (101 / totalCards)}%)`,
              width: `${totalCards * (100.7 / 4)}%`,
            }}
          >
            {(cards || []).map((product) => (
              <S.CardProductWrapper key={product.slug}>
                <Link href={`/${product.slug}/`}>
                  <Card {...product} description="A partir de" />
                </Link>
              </S.CardProductWrapper>
            ))}
          </S.CardsCarouselWrapper>
        )}
      </S.CardsCarouselContainer>
    </S.Carousel>
  );
};
