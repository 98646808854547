import {
  TCustomOption,
  TDenyRule,
  TProductCustomOptions,
  TProductOptionsDeny,
  TProductOptionsSelect,
} from '@mf/common/repositories/cdn/deny-rules/types';
import { TCustomFormatValue, TCustomPageValue } from '../product/product.types';
import {
  DeniedOptions,
  TCombination,
  TPartCombination,
} from './product-configuration.types';

interface GetDeniedOptionsProps {
  customCombination: TCombination;
  denyRules?: TDenyRule[];
  customFormat?: TCustomFormatValue | null;
  customPage?: TCustomPageValue | null;
}

interface ValidMatchSelectOptionsProps {
  selectOptions?: TProductOptionsSelect;
  selectCustomOptions?: TProductCustomOptions;
  selectPartId?: string;
  part: TPartCombination;
  customFormat?: TCustomFormatValue | null;
  customPage?: TCustomPageValue | null;
}

interface ValidMatchCustomSelectOptionsProps {
  selectOptions?: TProductOptionsSelect;
  selectCustomOptions?: TProductCustomOptions;
  part: TPartCombination;
  customFormat?: TCustomFormatValue | null;
  customPage?: TCustomPageValue | null;
}

interface ApplyRuleProps {
  deniedOptions: DeniedOptions;
  denyPartId?: string;
  denyOptions: TProductOptionsDeny;
  partIds: string[];
  part: TPartCombination;
  selectOptions?: TProductOptionsSelect;
  selectCustomOptions?: TProductCustomOptions;
}

interface AddDeniedOptionsProps {
  deniedOptions: DeniedOptions;
  partId: string;
  values: string[];
  attributeKey: string;
}

interface ValidCustomOrDefaultOptionProps {
  customFormat?: TCustomFormatValue | null;
  customPage?: TCustomPageValue | null;
  optionValue?: string | number | null;
  selectCustomOptions?: TProductCustomOptions;
  attribute: string;
  validAttribute: string;
}

interface DefaultValidOptionProps {
  optionValue?: string | number | null;
  values: string[];
}

interface ValidCustomOptionProps {
  customOption: TCustomOption;
  attribute: string;
  customFormat?: TCustomFormatValue | null;
  customPage?: TCustomPageValue | null;
}

interface ValidFormatProps {
  customOption: TCustomOption;
  customFormat?: TCustomFormatValue | null;
}

interface ValidPageProps {
  customOption: TCustomOption;
  customPage?: TCustomPageValue | null;
}

interface ValidPartOptionsProps {
  optionValue: string | number | null | undefined;
  values: string[];
  partId: string;
  selectPartId: string;
}

interface AddDenyQuantityProps {
  deniedOptions: DeniedOptions;
  values: string[];
}

export const configurationRules = () => {
  // eslint-disable-next-line
  const isNumericArray = (arr: any): arr is number[] => {
    return Array.isArray(arr) && arr.every((item) => typeof item === 'number');
  };

  const validPartOption = ({
    optionValue,
    values,
    partId,
    selectPartId,
  }: ValidPartOptionsProps) => {
    return (
      typeof optionValue === 'string' &&
      (values.includes(optionValue) || values.includes('*')) &&
      selectPartId === partId
    );
  };

  const validFormat = ({ customOption, customFormat }: ValidFormatProps) => {
    let boolWidth = false;
    let boolHeight = false;

    const { width, height, width_type, height_type } = customOption;

    if (!customFormat) {
      return true;
    }

    // validação largura
    if (typeof width === 'number') {
      if (width_type === '>') {
        boolWidth = customFormat.width > width;
      } else if (width_type === '<') {
        boolWidth = customFormat.width < width;
      }
    } else if (isNumericArray(width)) {
      if (width_type === '=') {
        boolWidth = width.includes(customFormat.width);
      }
      if (width_type === '!=') {
        boolWidth = !width.includes(customFormat.width);
      }
    }

    // validação altura
    if (typeof height === 'number') {
      if (height_type === '>') {
        boolHeight = customFormat.height > height;
      } else if (height_type === '<') {
        boolHeight = customFormat.height < height;
      }
    } else if (isNumericArray(height)) {
      if (height_type === '=') {
        boolHeight = height.includes(customFormat.height);
      }
      if (height_type === '!=') {
        boolHeight = !height.includes(customFormat.height);
      }
    }

    return boolWidth && boolHeight;
  };

  const validPage = ({ customOption, customPage }: ValidPageProps) => {
    const { id, type } = customOption;

    if (!customPage) {
      return true;
    }

    if (typeof id === 'number') {
      if (type === '>') {
        return customPage.pages > id;
      } else if (type === '<') {
        return customPage.pages < id;
      }
    } else if (isNumericArray(id)) {
      if (type === '=') {
        return id.includes(customPage.pages);
      }
      if (type === '!=') {
        return !id.includes(customPage.pages);
      }
    }

    return false;
  };

  const validCustomOption = ({
    customOption,
    attribute,
    customFormat,
    customPage,
  }: ValidCustomOptionProps): boolean => {
    if (attribute === 'format') {
      return validFormat({ customOption, customFormat });
    } else if (attribute === 'page') {
      return validPage({ customOption, customPage });
    }

    return false;
  };

  const defaultValidOption = ({
    optionValue,
    values,
  }: DefaultValidOptionProps) => {
    return (
      typeof optionValue === 'string' &&
      (values.includes(optionValue) || values.includes('*'))
    );
  };

  const validCustomOrDefaultOption = ({
    customFormat,
    customPage,
    selectCustomOptions,
    attribute,
    validAttribute,
    optionValue,
  }: ValidCustomOrDefaultOptionProps) => {
    if (
      selectCustomOptions &&
      selectCustomOptions[validAttribute] &&
      attribute === validAttribute
    ) {
      const customOption = selectCustomOptions[validAttribute];

      if (optionValue && String(optionValue).includes('custom')) {
        return validCustomOption({
          customFormat,
          customPage,
          customOption,
          attribute,
        });
      }
    }

    return;
  };

  const validMatchSelectOptions = ({
    customFormat,
    customPage,
    selectCustomOptions,
    selectOptions,
    selectPartId,
    part,
  }: ValidMatchSelectOptionsProps) => {
    if (selectOptions) {
      return Object.entries(selectOptions).every(([attribute, values]) => {
        const optionValue =
          part.options[attribute as keyof typeof part.options];

        const validPage = validCustomOrDefaultOption({
          optionValue,
          selectCustomOptions,
          attribute,
          validAttribute: 'page',
          customFormat,
          customPage,
        });

        const validFormat = validCustomOrDefaultOption({
          optionValue,
          selectCustomOptions,
          attribute,
          validAttribute: 'format',
          customFormat,
          customPage,
        });

        // Verifica se há especificação de parte atual de seleçao
        if (selectPartId) {
          const partOption = validPartOption({
            optionValue,
            values,
            selectPartId,
            partId: part.id,
          });

          if (typeof validPage !== 'undefined') {
            return partOption || validPage;
          }

          if (typeof validFormat !== 'undefined') {
            return partOption || validFormat;
          }

          return partOption;
        }

        // Verifica se os atributos selecionados estão incluidos nas opções de seleção
        const defaultOption = defaultValidOption({
          optionValue,
          values,
        });

        if (typeof validPage !== 'undefined') {
          return defaultOption || validPage;
        }

        if (typeof validFormat !== 'undefined') {
          return defaultOption || validFormat;
        }

        return defaultOption;
      });
    }

    return false;
  };

  const validMatchCustomSelectOptions = ({
    customFormat,
    customPage,
    selectCustomOptions,
    selectOptions,
    part,
  }: ValidMatchCustomSelectOptionsProps) => {
    if (selectCustomOptions) {
      return Object.entries(selectCustomOptions).every(
        ([attribute, customOption]) => {
          // Somente 'page' e 'format' possuem opções personalizadas
          if (['page', 'format'].includes(attribute)) {
            if (selectOptions) {
              if (selectOptions['format']) {
                return true;
              }

              if (selectOptions['page']) {
                return true;
              }
            }

            const optionValue =
              part.options[attribute as keyof typeof part.options];

            if (optionValue && String(optionValue).includes('custom')) {
              return validCustomOption({
                customOption,
                attribute,
                customFormat,
                customPage,
              });
            }
          }
          return false;
        },
      );
    }

    return false;
  };

  const addDenyQuantity = ({ deniedOptions, values }: AddDenyQuantityProps) => {
    const partId = 'quantity';

    if (!deniedOptions[partId]) {
      deniedOptions[partId] = {};
    }

    if (!deniedOptions[partId]?.values) {
      deniedOptions[partId].values = [];
    }

    const lastValue = Number(deniedOptions[partId]?.values[0]);
    const newValue = Number(values[0]);
    const type = deniedOptions[partId]?.values[1];

    if (!isNaN(lastValue) && !isNaN(newValue)) {
      const shouldUpdate =
        (type === '<' && lastValue < newValue) ||
        (type === '>' && lastValue > newValue) ||
        (type !== '<' && type !== '>');

      if (shouldUpdate) {
        deniedOptions[partId].values = values;
      }
    } else {
      deniedOptions[partId].values = values;
    }
  };

  const addDeniedOptions = ({
    deniedOptions,
    partId,
    attributeKey,
    values,
  }: AddDeniedOptionsProps) => {
    if (!deniedOptions[partId]) {
      deniedOptions[partId] = {};
    }

    if (!deniedOptions[partId][attributeKey]) {
      deniedOptions[partId][attributeKey] = [];
    }

    deniedOptions[partId][attributeKey].push(...values);
  };

  const applyRule = ({
    part,
    partIds,
    denyPartId,
    denyOptions,
    selectOptions,
    deniedOptions,
    selectCustomOptions,
  }: ApplyRuleProps) => {
    if (denyPartId) {
      Object.entries(denyOptions).forEach(([attribute, values]) => {
        addDeniedOptions({
          deniedOptions,
          partId: denyPartId,
          attributeKey: attribute,
          values,
        });
      });
      // Se não, aplica a todas as partes
    } else {
      Object.entries(denyOptions).forEach(([attribute, values]) => {
        partIds.forEach((partId, key) => {
          // Se a seleção for igual a parte atual
          if (part.id === partId) {
            // verifica se não é o mesmo atributo atual selecionado
            if (selectOptions) {
              if (!Object.keys(selectOptions).includes(attribute)) {
                addDeniedOptions({
                  attributeKey: attribute,
                  deniedOptions,
                  partId,
                  values,
                });
              }
            }
            // verifica em customizados também
            else if (selectCustomOptions) {
              if (!Object.keys(selectCustomOptions).includes(attribute)) {
                addDeniedOptions({
                  attributeKey: attribute,
                  deniedOptions,
                  partId,
                  values,
                });
              }
            }
            // Senão adiciona normalmente
          } else {
            if (attribute === 'format') {
              if (key !== 0) {
                addDeniedOptions({
                  deniedOptions,
                  partId,
                  values,
                  attributeKey: attribute,
                });
              }
            } else {
              addDeniedOptions({
                deniedOptions,
                partId,
                values,
                attributeKey: attribute,
              });
            }
          }
        });
      });
    }
  };

  const getDeniedOptions = ({
    denyRules,
    customPage,
    customFormat,
    customCombination,
  }: GetDeniedOptionsProps) => {
    const deniedOptions = {};
    const parts = customCombination.parts;
    const partIds = parts.map((part) => part.id);

    parts.forEach((part) => {
      denyRules?.forEach((rule) => {
        const selectOptions = rule.select?.product_options;
        const selectCustomOptions = rule.select?.product_options_custom;
        const selectPartId = rule.select?.product_part_id;
        const denyOptions = rule.deny?.product_options;
        const denyPartId = rule.deny?.product_part_id;
        const denyQuantity = rule?.deny?.product_quantities_custom;
        const denyQuantityType = rule?.deny?.product_quantities_type;

        let finalMatch = false;
        let matchesAllSelects = false;
        let matchesAllCustomSelects = false;

        matchesAllSelects = validMatchSelectOptions({
          selectCustomOptions,
          selectOptions,
          selectPartId,
          part,
          customFormat,
          customPage,
        });

        matchesAllCustomSelects = validMatchCustomSelectOptions({
          selectCustomOptions,
          selectOptions,
          part,
          customFormat,
          customPage,
        });

        if (selectOptions && selectCustomOptions) {
          finalMatch = matchesAllSelects && matchesAllCustomSelects;
        } else {
          finalMatch = matchesAllSelects || matchesAllCustomSelects;
        }

        if (finalMatch) {
          if (denyOptions) {
            applyRule({
              part,
              partIds,
              denyPartId,
              denyOptions,
              selectOptions,
              deniedOptions,
              selectCustomOptions,
            });
          }

          if (denyQuantity && denyQuantityType) {
            addDenyQuantity({
              deniedOptions,
              values: [denyQuantity.toString(), denyQuantityType],
            });
          }
        }
      });
    });

    return deniedOptions;
  };

  return {
    getDeniedOptions,
  };
};
