import { api } from '@mf/common/config/axios/serviceApi';
import { TAxiosDefaultConfig } from '@mf/common/repositories/aecomStrapiCms/defaults/types';
import { AxiosInstance } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  ItemStatusHistory,
  ItemsFromOrderID,
  OrderByID,
  ProductMetaInfo,
  TApplyVoucherPayload,
  TApplyVoucherResponse,
  TCheckoutOrderPayload,
  TCheckoutOrderResponse,
  TInstallments,
  TOrderDetails,
  TShippingQuote,
  TShippingQuotePickup,
  TShippingQuoteResponse,
} from './types';

const bffCheckout = ({ baseUrl, token }: TAxiosDefaultConfig) => {
  const apiBff: AxiosInstance = api({ baseUrl, token });

  const applyVoucher = async (payload: TApplyVoucherPayload) => {
    const response: {
      data: TApplyVoucherResponse;
    } = await apiBff.post('/v1/checkout/apply-voucher', payload);

    return response.data;
  };

  const createOrder = async (payload: TCheckoutOrderPayload) => {
    const response: {
      data: TCheckoutOrderResponse;
    } = await apiBff.post('/v1/checkout/submit', payload);

    return response.data;
  };

  const getOrderById = async (
    orderId: string,
  ): Promise<TOrderDetails | undefined> => {
    const requests = [
      apiBff.get(`/v1/my-account/orders/${orderId}`),
      apiBff.get(`/v1/my-account/orders/${orderId}/items-status-history`),
      apiBff.get(`/v1/my-account/orders/${orderId}/items`),
    ];

    const [orderResponse, statusResponse, cartResponse] =
      await Promise.allSettled(requests);

    // Lidando com possíveis erros ou códigos de status 403
    const order: OrderByID =
      orderResponse.status === 'fulfilled' ? orderResponse.value.data : {};
    const status: ItemStatusHistory =
      statusResponse.status === 'fulfilled' ? statusResponse.value.data : {};
    const cart =
      cartResponse.status === 'fulfilled' ? cartResponse.value.data.data : [];

    if (cart.length > 0) {
      const itensQuery = cart
        .map(
          (item: ItemsFromOrderID) =>
            `filters[final_product_id][$in][]=${item.info.final_product_id}`,
        )
        .join('&');

      const itemsResponse = await apiBff.get(
        `v1/products?fields[0]=name&fields[1]=slug&fields[2]=final_product_id&populate=banner_image&${itensQuery}`,
      );
      const items: ProductMetaInfo = itemsResponse.data;

      return { order, status, cart, items };
    } else {
      return { order, status, cart, items: {} as ProductMetaInfo };
    }
  };

  const shippingQuote = async (
    payload: TShippingQuote | TShippingQuotePickup,
  ) => {
    const response = await apiBff.post('/v1/checkout/shipping-quote', payload);

    return response;
  };

  const typedShippinQuote = async (
    payload: TShippingQuote | TShippingQuotePickup,
  ) => {
    const { data } = await apiBff.post<TShippingQuoteResponse[]>(
      `/v1/checkout/shipping-quote?simulate=${payload?.simulate ? 'true' : 'false'}`,
      payload,
    );

    return data;
  };

  const useCreateOrderMutation = () => {
    return useMutation(createOrder);
  };

  const useApplyVoucherMutation = () => {
    return useMutation(applyVoucher);
  };

  const useGetOrderById = (orderId: string) => {
    return useQuery({
      queryKey: ['checkout-orderplaced'],
      queryFn: () => getOrderById(orderId),
      enabled: !!token,
    });
  };

  const getInstallments = async (cartTotal: number) => {
    const payload: TInstallments = {
      value: cartTotal,
    };

    const response = await apiBff.post('/v1/payment/payment-options', payload);

    return response;
  };

  const useInstallmentsMutation = () => {
    return useMutation(getInstallments);
  };

  const useShippingQuote = () => {
    return useMutation(typedShippinQuote);
  };

  return {
    createOrder,
    shippingQuote,
    getInstallments,
    getOrderById,
    useCreateOrderMutation,
    useInstallmentsMutation,
    useGetOrderById,
    applyVoucher,
    useApplyVoucherMutation,
    useShippingQuote,
  };
};

export default bffCheckout;
