import {
  DeniedOptions,
  TCombination,
  TInvisibleOptions,
  TPartsAttributesOptions,
} from './product-configuration.types';

interface UpdateCustomCombinationProps {
  customCombination: TCombination;
  updateParams: {
    partId: string;
    attributeId: string;
    optionId: string;
  };
}

interface UpdateInvisibleOptionsProps {
  invisibleOptions: TInvisibleOptions;
  updateParams: {
    partId: string;
    attributeId: string;
    attributeOptions: TPartsAttributesOptions[];
  };
}

interface FilterAndUpdateInvisibleByDeniedProps {
  invisibleOptions: TInvisibleOptions;
  deniedOptions: DeniedOptions;
}

interface CheckIfIsTheSameOptionProps {
  customCombination: TCombination;
  searchParams: {
    partId: string;
    attributeId: string;
    optionId: string;
  };
}

interface CheckIfIsAllConfiguredProps {
  customCombination: TCombination;
  totalAttributes: number;
}

interface CheckIfAttributeHasValidOptionProps {
  deniedOptions: DeniedOptions;
  customCombination: TCombination;
  searchParams: {
    partId: string;
    attributeId: string;
  };
}

export const configurationFunctions = () => {
  const checkIfIsTheSameOption = ({
    customCombination,
    searchParams,
  }: CheckIfIsTheSameOptionProps) => {
    const combination = { ...customCombination };
    const parts = combination.parts;
    const { partId, attributeId, optionId } = searchParams;

    const part = parts.find((part) => part.id === partId);

    if (part) {
      const attributeKey = attributeId as keyof typeof part.options;
      return part.options?.[attributeKey] === optionId;
    }

    return false;
  };

  const filterAnUpdateInvisibleByDenied = ({
    invisibleOptions,
    deniedOptions,
  }: FilterAndUpdateInvisibleByDeniedProps) => {
    // deep copy
    const updatedInvisible = {
      ...invisibleOptions,
      parts: invisibleOptions.parts.map((part) => ({
        ...part,
        options: { ...part.options },
      })),
    };

    updatedInvisible.parts.forEach((part) => {
      const deniedPart = deniedOptions[part.id];

      if (deniedPart) {
        Object.keys(part.options).forEach((optionKey) => {
          const deniedIds = deniedPart[optionKey];

          if (deniedIds) {
            const opt = optionKey as keyof typeof part.options;
            part.options[opt] = part?.options?.[opt]?.filter(
              (option) => !deniedIds.includes(option.id),
            );
          }
        });
      }
    });

    return updatedInvisible;
  };

  const updateInvisibleOptions = ({
    invisibleOptions,
    updateParams,
  }: UpdateInvisibleOptionsProps) => {
    const updatedInvisibleOptions = { ...invisibleOptions };

    const { partId, attributeId, attributeOptions } = updateParams;
    const parts = updatedInvisibleOptions.parts;

    const partIndex = parts.findIndex((part) => part.id === partId);

    if (partIndex !== -1) {
      const part = parts[partIndex];
      const attribute = attributeId as keyof typeof part;

      part.options[attribute as keyof typeof part.options] = attributeOptions;
      updatedInvisibleOptions.parts[partIndex] = part;
    } else {
      const part = {
        id: partId,
        options: {
          [attributeId]: attributeOptions,
        },
      };
      updatedInvisibleOptions.parts.push(part);
    }

    return updatedInvisibleOptions;
  };

  const updateCustomCombination = ({
    customCombination,
    updateParams,
  }: UpdateCustomCombinationProps) => {
    const updatedCustomCombination = { ...customCombination };

    const { partId, attributeId, optionId } = updateParams;
    const parts = updatedCustomCombination.parts;

    const partIndex = parts.findIndex((part) => part.id === partId);

    if (partIndex !== -1) {
      const part = parts[partIndex];
      const attribute = attributeId as keyof typeof part;

      part.options[attribute as keyof typeof part.options] = optionId;
      updatedCustomCombination.parts[partIndex] = part;
    } else {
      const part = {
        id: partId,
        options: {
          [attributeId]: optionId,
        },
      };
      updatedCustomCombination.parts.push(part);
    }

    return updatedCustomCombination;
  };

  const checkIfIsAllConfigured = ({
    customCombination,
    totalAttributes,
  }: CheckIfIsAllConfiguredProps) => {
    // deep copy
    const combination = {
      ...customCombination,
      parts: customCombination.parts.map((part) => ({
        ...part,
        options: {
          ...part.options,
        },
      })),
    };
    const parts = combination.parts;

    const totalConfiguredAttributes = parts.reduce((sum, part) => {
      const options = Object.values(part.options);

      const validOptionsCount = options.filter(
        (option) => option != null,
      ).length;

      return sum + validOptionsCount;
    }, 0);

    return totalConfiguredAttributes === totalAttributes;
  };

  const checkIfHasAttributeValidOption = ({
    searchParams,
    deniedOptions,
    customCombination,
  }: CheckIfAttributeHasValidOptionProps) => {
    const combination = { ...customCombination };
    const parts = combination.parts;
    const { partId, attributeId } = searchParams;

    const part = parts.find((part) => part.id === partId);

    if (!part) {
      return true;
    }

    const attribute = attributeId as keyof typeof part.options;
    const selectedAttributeOption = part.options[attribute];

    if (!selectedAttributeOption) {
      return false;
    }

    const notAvailable = deniedOptions?.[partId]?.[attributeId];

    if (!notAvailable || !selectedAttributeOption) {
      return true;
    }

    return !notAvailable.includes(selectedAttributeOption);
  };

  return {
    checkIfIsAllConfigured,
    checkIfIsTheSameOption,
    updateInvisibleOptions,
    updateCustomCombination,
    checkIfHasAttributeValidOption,
    filterAnUpdateInvisibleByDenied,
  };
};
