import { create } from 'zustand';
import { ProductConfigurationStore } from './product-configuration.types';
import { configurationFunctions } from './product-configuration.functions';
import { configurationRules } from './product-configuration.rules';

const {
  checkIfIsAllConfigured,
  checkIfIsTheSameOption,
  updateCustomCombination,
  updateInvisibleOptions,
  checkIfHasAttributeValidOption,
  filterAnUpdateInvisibleByDenied,
} = configurationFunctions();

const initialData = {
  totalSteps: 0,
  totalAttributes: 0,
  customCombination: {
    id: 'custom',
    name: 'Feito por você',
    parts: [],
    is_visible: true,
  },
  deniedOptions: {},
  invisibleOptions: { parts: [] },
  filteredInvisibleOptions: { parts: [] },
  isAllConfigured: false,
  quantity: {
    quantity: 0,
    price: 0,
    unit_price: 0,
    token: '',
  },
  defaultCombination: null,
  customFormatOption: null,
  customPageOption: null,
  customQuantityOption: null,
  isLoadingQuantities: false,
  economicShipping: null,
  lastSelectedAttribute: '',
  customPage: null,
  customFormat: null,
  invalidSummary: true,
  isLoadingShipping: false,
};

const { getDeniedOptions } = configurationRules();

export const useProductConfigurationStore = create<ProductConfigurationStore>(
  (set) => ({
    resetStore: () => set(initialData),
    totalSteps: 0,
    setTotalSteps: (totalSteps) => set({ totalSteps }),

    totalAttributes: 0,
    setTotalAttributes: (totalAttributes) => set({ totalAttributes }),

    customCombination: {
      id: 'custom',
      name: 'Feito por você',
      parts: [],
      is_visible: true,
    },
    setCustomCombination: (customCombination) => set({ customCombination }),
    updateCustomCombination: (props) =>
      set((state) => {
        const updatedCombination = updateCustomCombination({
          customCombination: state.customCombination,
          updateParams: props,
        });

        return {
          customCombination: updatedCombination,
        };
      }),

    deniedOptions: {},
    updateDeniedOptions: (props) =>
      set((state) => {
        const deniedOptions = getDeniedOptions({
          customCombination: state.customCombination,
          denyRules: props.denyRules,
          customFormat: state.customFormat,
          customPage: state.customPage,
        });

        return {
          deniedOptions,
        };
      }),

    invisibleOptions: { parts: [] },
    filteredInvisibleOptions: { parts: [] },
    updateInvisibleOptions: (props) =>
      set((state) => {
        const updatedInvisibleOptions = updateInvisibleOptions({
          invisibleOptions: state.invisibleOptions,
          updateParams: props,
        });

        return {
          invisibleOptions: updatedInvisibleOptions,
        };
      }),
    filterAnUpdateInvisibleByDenied: () =>
      set((state) => {
        const props = {
          deniedOptions: state.deniedOptions,
          invisibleOptions: state.invisibleOptions,
        };

        const filteredInvisibleOptions = filterAnUpdateInvisibleByDenied(props);

        return {
          filteredInvisibleOptions: filteredInvisibleOptions,
        };
      }),

    checkIfIsTheSameOption: (props) => {
      return checkIfIsTheSameOption(props);
    },

    isAllConfigured: false,
    setIsAllConfigured: (isAllConfigured) => set({ isAllConfigured }),
    checkIfIsAllConfigured: () =>
      set((state) => {
        const isAllConfigured = checkIfIsAllConfigured({
          customCombination: state.customCombination,
          totalAttributes: state.totalAttributes,
        });

        return {
          isAllConfigured,
        };
      }),

    quantity: {
      quantity: 0,
      price: 0,
      unit_price: 0,
      token: '',
    },
    selectQuantity: (quantity) => set({ quantity }),

    defaultCombination: null,
    setDefaultCombination: (defaultCombination) => set({ defaultCombination }),

    checkIfHasAttributeValidOption: (props) => {
      return checkIfHasAttributeValidOption(props);
    },

    customFormatOption: null,
    setCustomFormatOption: (customFormatOption) => set({ customFormatOption }),

    customPageOption: null,
    setCustomPageOption: (customPageOption) => set({ customPageOption }),

    customQuantityOption: null,
    setCustomQuantityOption: (customQuantityOption) =>
      set({ customQuantityOption }),

    isLoadingQuantities: false,
    setIsLoadingQuantities: (isLoadingQuantities) =>
      set({ isLoadingQuantities }),

    economicShipping: null,
    setEconomicShipping: (economicShipping) => set({ economicShipping }),

    lastSelectedAttribute: '',
    setLastSelectedAttribute: (lastSelectedAttribute) =>
      set({ lastSelectedAttribute }),

    customPage: null,
    setCustomPage: (customPage) => set({ customPage }),

    customFormat: null,
    setCustomFormat: (customFormat) => set({ customFormat }),

    invalidSummary: true,
    setIsInvalidSummary: (invalidSummary) => set({ invalidSummary }),

    isLoadingShipping: false,
    setIsLoadingShipping: (isLoadingShipping) => set({ isLoadingShipping }),
  }),
);
