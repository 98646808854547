import { FsListContent } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  > div {
    &[type='square'] {
      height: var(--spacing-8x) !important;
      width: 100% !important;
      margin-bottom: 20px;
    }
  }
`;

export const ListContent = styled(FsListContent)`
  width: -webkit-fill-available;

  div[orientation='Horizontal'] {
    flex: 1 0 0;

    span {
      margin-bottom: 0 !important;
    }

    :first-child {
      gap: 0;
    }
  }
`;
