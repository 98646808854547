import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import {
  DrawerPositions,
  DrawerSizes,
  FsDrawer,
  FsSkeleton,
  HeadingTag,
  ListContentType,
  ListContentVariant,
} from '@printi/ds-offset-react-core';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Image from 'next/image';
import { TCartItems } from '@mf/common/repositories/bff/cart/lastCart/types';
import * as S from './DrawerTemplates.styles';
import {
  FileWithOrientation,
  useDrawerTemplatesHooks,
} from './DrawerTemplates.hooks';

interface DrawerTemplatesProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  rawAttributes?: TCartItems['parts'];
  finalProductId?: string;
}

export const DrawerTemplates = ({
  isOpen,
  setOpen,
  rawAttributes,
  finalProductId,
}: DrawerTemplatesProps) => {
  const [orderTemplates, setOrderTemplates] = useState<FileWithOrientation[]>(
    [],
  );

  const { customCombination } = useProductConfigurationStore();
  const {
    templates,
    isLoading,
    getProps,
    getOrientation,
    sortFilesByType,
    fetchTemplates,
    isSuccess,
  } = useDrawerTemplatesHooks();

  const onClose = () => {
    setOrderTemplates([]);
    setOpen(false);
  };

  useEffect(() => {
    setOrderTemplates([]);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (isOpen && rawAttributes) {
      const parts = rawAttributes.map((part) => {
        return {
          id: part.id,
          options: part.raw_attributes,
        };
      });

      fetchTemplates({ parts, finalProductId });
      return;
    }

    fetchTemplates({ parts: customCombination.parts, finalProductId });
    // eslint-disable-next-line
  }, [isOpen, customCombination, finalProductId]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (!templates || !templates?.data || !isSuccess || !isOpen) {
      setOrderTemplates([]);
      return;
    }

    if (templates.status !== 200) {
      setOrderTemplates([]);
      return;
    }

    const orderData = sortFilesByType(templates?.data);
    setOrderTemplates(orderData);
    // eslint-disable-next-line
  }, [templates, isLoading, isSuccess, isOpen]);

  //TO DO ListTemplates
  return (
    <FsDrawer
      isOpen={isOpen}
      showNavBar
      className="drawer-base-config"
      heading="Gabaritos"
      position={DrawerPositions.Right}
      size={DrawerSizes.SM}
      onClose={onClose}
      navBarHeadingProps={{
        tag: HeadingTag.H2,
      }}
      disabled={isLoading}
    >
      <S.Container>
        {isLoading ? (
          <>
            <FsSkeleton />
            <FsSkeleton />
            <FsSkeleton />
            <FsSkeleton />
          </>
        ) : (
          <>
            {orderTemplates.map((template, key) => {
              const props = getProps(template.type);

              if (template.type === 'canva') {
                return template?.parts?.map((canva) => (
                  <S.ListContent
                    key={props?.tag}
                    showButton
                    type={ListContentType.Image}
                    variant={ListContentVariant.Line}
                    heading={props?.name}
                    buttonLabel="Abrir"
                    showLabelLeft={false}
                    tagText={props?.tag}
                    img={
                      <Image
                        src={props?.img}
                        alt={props?.name}
                        width={40}
                        height={40}
                      />
                    }
                    labelRight={''}
                    description={''}
                    lastItem={orderTemplates.length - 1 === key}
                    onButtonClick={() => {
                      window.open(canva.url, '_blank');
                    }}
                  />
                ));
              } else {
                return (
                  <S.ListContent
                    key={props?.tag}
                    showButton
                    type={ListContentType.Image}
                    variant={ListContentVariant.Line}
                    heading={props?.name}
                    buttonLabel="Baixar"
                    showLabelLeft={false}
                    tagText={props?.tag}
                    img={
                      <Image
                        src={props?.img}
                        alt={props?.name}
                        width={40}
                        height={40}
                      />
                    }
                    labelRight={''}
                    description={getOrientation(template.orientation) || ''}
                    lastItem={orderTemplates.length - 1 === key}
                    onButtonClick={() => {
                      window.location.href = template.url;
                      window.dataLayer.push({
                        event: 'genericEvent',
                        event_name: 'download_gabarito',
                        event_parameter_1: props?.name,
                      });
                    }}
                  />
                );
              }
            })}
          </>
        )}
      </S.Container>
    </FsDrawer>
  );
};
