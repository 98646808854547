import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';

export const Carousel = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3x);
  width: 100%;

  @media screen and (min-width: 768px) {
    padding-bottom: var(--spacing-10x) !important;
  }

  ${media.greaterThan('mobile')} {
    gap: var(--spacing-4x);
  }
`;

export const HeadingControlsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: none;
  gap: var(--spacing-1x);
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const CardsCarouselContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: var(--spacing-half);
  width: 100%;
  @media screen and (min-width: 768px) {
    overflow: hidden;
  }
  overflow-x: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${media.lessThan('mobile')} {
    margin: 0 -16px;
    padding: 0 16px;
    width: auto;
  }
`;

export const CardsCarouselWrapper = styled.div`
  display: flex;
  gap: var(--spacing-2x);
  transition: transform 250ms ease-in-out;
  width: max-content;
  @media screen and (min-width: 768px) {
    gap: var(--spacing-3x);
  }
`;

export const CardProductWrapper = styled.div`
  display: flex;
  min-width: 240px;
  width: 240px;
  cursor: pointer;
  ${media.greaterThan('touch')} {
    width: 322px;
  }
`;
